import React, { useEffect } from 'react'
import { Avatar } from '@mui/material'
import './myAvatar.css'
import {titleNormalized, appLanguage} from "../../select";


const MyAvatar = () => {

  // a supprimer plus tard rajouter juste pour faire correspondre l avatar aux videos 
  let tmpTitle = titleNormalized
  if(appLanguage == 'chinese' && titleNormalized == 'chatbot')
    tmpTitle = 'chatbot-hair'
    return (
        <Avatar
        id={"avatar-logo"}
        alt="bot"
        src={localStorage.getItem('avatar') || ("/assets/images/profil-" + tmpTitle + ".png")}
        sx={{ width: 56, height: 56 }}
      />
    )
}

export default MyAvatar
