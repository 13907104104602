import React, { useState, useEffect } from 'react';
import './slide2unlock.css';

export default function SlideToUnlock ({unlocked}) {
    const [sliderPosition, setSliderPosition] = useState(0);
    const [direction, setDirection] = useState(1);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [moseDown, setMoseDown] = useState(false);

    // effet demander par dan 
    useEffect(() => {
      if (!animationCompleted) {
          document.querySelector('.slider').style.transition = 'left 0.5s';
          const interval = setInterval(() => {
              setSliderPosition(prevPosition => {
                  const newPosition = prevPosition + (10 * direction);
                  if (newPosition >= 200 || newPosition <= 0) {
                      setDirection(prevDirection => -prevDirection);
                      if (newPosition <= 0) {
                          setAnimationCompleted(true);
                      }
                  }
                  return newPosition;
              });
          }, 20);
          return () => clearInterval(interval);
      }
  }, [direction, animationCompleted]);

    const handleMouseDown = (e) => {
        setMoseDown(true);
    };
  
    const handleMouseMove = (e) => {
        document.querySelector('.slider').style.transition = 'none';
        const newPosition = (e.clientX || e.touches[0].clientX) - (e.target.getBoundingClientRect().left);
        if(!moseDown || newPosition < 0 || newPosition > 230)
            return;
        setSliderPosition(newPosition);
    };
  
    const handleMouseUp = (e) => {
        setMoseDown(false);
      if (sliderPosition >= 200) {
        unlocked(true)
      }
      document.querySelector('.slider').style.transition = 'left 0.5s';
      setSliderPosition(0);
    };
  
    return (
      <div className="slide-to-unlock">
          <div className="slide-to-unlock-bc"
               onMouseDown={handleMouseDown}
               onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onTouchMove={handleMouseMove}
               onTouchStart={handleMouseDown}
               onTouchEnd={handleMouseUp}
          ></div>
        <div className="slider" style={{ left: `${sliderPosition}px`,backgroundImage: 'url(/assets/images/logo-gyd.png)', height: '60px', width: '60px'}}></div>
        <div className="unlock-text" style={{display: (sliderPosition > 10? 'none' : 'flex')}}>Commencer votre diagnostic</div>
      </div>
    );
  };
