import React, { useState, useEffect, useRef} from 'react';
import { Button, Box } from '@mui/material';
import './oneButton.css';
import { createSalonChatCustomer, updatefollowingChat } from '../../api/service';
import {titleNormalized} from "../../select";

const OneButton = (props) => {
   // const { setState, payload, actionProvider, checkedProducts, checkedSoins, states } = props;
    const { setState, payload, states } = props;
    const { url, urlAbbi, content } = payload;
    const [isVisible, setIsVisible] = useState(false);
    const buttonRef = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if(isVisible && !titleNormalized.includes('hair'))
            buttonRef.current.scrollIntoView({block: 'end'});
    }, [isVisible]);


    /*const postChoices = async () => {
        try {
            let id = localStorage.getItem('followingCactusId');
            const checkedProductsClean = checkedProducts.filter((element) => element !== '');
            const obj = {
                organization_id: localStorage.getItem("organizationId"),
                customer_id: states.user.customer_id,
                order_id: localStorage.getItem("chatIdHair"),
                //json_data: JSON.stringify({ ...states.json_data, selected_products: checkedProductsClean.join(','), checkedSoins: checkedSoins.join(',') })
                json_data: JSON.stringify({ ...states.json_data})
            };
           // await createSalonChatCustomer(obj);
           // await updatefollowingChat({ selected_products: checkedProductsClean.join(',') }, id);
        } catch (error) {
            console.error(error);
        }
    };*/
    

    const handleClick = async() => {
        
    if (url.includes('chatbot-hair-ybera')) {
            // await postChoices()
            window.location = url;
        }
        else if (urlAbbi) {
            window.open(`${urlAbbi}`, '_blank');
        }
        else{
            window.location = url;
        }
    }

    return (
        isVisible && (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    padding: '0 5%',
                }}
            >
                <Button   ref={buttonRef} onClick={handleClick}>{content}</Button>
            </Box>
        )
    );
};

export default OneButton;
