import React, {useEffect} from 'react';
import {Langage} from "../language";
import {appLanguage} from "../select";

const CustomMessage = (props) => {

  const getText = (key) => {
    return Langage[key][appLanguage];
  }

  useEffect(() => {
    if (props.payload === 'start') {
        props.actions.next(getText('start_diag'), 0);
    }
  }, [props.payload]);
  return (
    <>
    </>
  );
};

export default CustomMessage;
