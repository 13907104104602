import React, { useEffect } from 'react'
import './myHeader.css'
import {titleNormalized, appLanguage} from "../../select";
import {AppName} from "../../language";


const MyHeader = (props) => {

  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'chatbot-hair-ybera': return 'Confidences - Le salon';
      default: return 'Cactus';
    }
  }

  return (
        <div className={'header'}>
          <div><img src="/assets/images/logo-head.png" alt=""/></div>
          <div className={'botName'}><img src="/assets/images/chat-active.png" alt=""/> {getAppName(titleNormalized)}</div>
          <div className={'botImg'}><img src={localStorage.getItem('avatar') || ("/assets/images/profil-" + titleNormalized + ".png")} id={'botImg'}/></div>
        </div>
  )
}

export default MyHeader
