import { useCallback, useState, useEffect, useRef } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Slider from 'react-slick';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Checkbox } from '@mui/material';
import './productCarousel.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getDefaultBrand, getProductByRating, getProducts, getSoinCheveuxByRating, getSoinLavantByRating } from '../../../api/service';
import OneButton from '../../oneButton/oneButton';
import { getSummary } from '../../../api/service-hair';
import { titleNormalized } from '../../../select';

function ProductCarousel(props) {

  const { payload, setState } = props
  const { id } = payload;
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [checkedSoins, setCheckedSoins] = useState([]);
  const [soinCheveux, setSoinCheveux] = useState([]);
  const [soinLavants, setSoinLavants] = useState([]);
  const [soinCheveuxByMarque, setSoinCheveuxByMarque] = useState([]);
  const [soinLavantsByMarque, setSoinLavantsByMarque] = useState([]);
  const [selectedMarque, setSelectedMarque] = useState('Toutes les marques');
  const [selectedMarque1, setSelectedMarque1] = useState('Toutes les marques');
  const [filterMarque, setFilterMarque] = useState('Toutes les marques');
  const [filterMarque1, setFilterMarque1] = useState('Toutes les marques');
  const [summaryDiag, setSummaryDiag] = useState(null);
  const brands = ['Toutes les marques', 'YBERA', 'Davines', 'Kérastase Shampoo', 'Kérastase', 'Wella', "L'oréal"];
  const sliderRefSoinsCheveux = useRef(null);
  const sliderRefSoinsLavants = useRef(null);
  const mainDivRef = useRef(null);

  useEffect(() => {
    props.scrollIntoView()
  }, []);

  const goToNextSlide = (type) => {
    if (type === 'soinCheveux') {
      sliderRefSoinsCheveux.current.slickNext(); // Utilisez la fonction slickNext() du Slider pour passer au slide suivant
    }
    if (type === 'soinLavant') {
      sliderRefSoinsLavants.current.slickNext(); // Utilisez la fonction slickNext() du Slider pour passer au slide suivant
    }
  };

  const handleCheckedProducts = (eans, addFlag, idSoin) => {
    if (addFlag) {
      setCheckedProducts((prevCheckedProducts) => [
        ...prevCheckedProducts,
        ...eans
      ]);
      setCheckedSoins((prevCheckedSoins) => [
        ...prevCheckedSoins,
        idSoin
      ])
    } else {
      setCheckedProducts((prevCheckedProducts) =>
        prevCheckedProducts.filter((productUid) => !eans.includes(productUid))
      );
      setCheckedSoins((prevCheckedSoins) =>
        prevCheckedSoins.filter((uid) => idSoin !== uid)
      );
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // id = localStorage.getItem('chatIdHair')
        const data = await getSummary(id);
        setSummaryDiag(data.summary)
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData();
  }, [id]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let marque
        filterMarque === 'Toutes les marques' ? marque = null : marque = filterMarque
        filterMarque1 === 'Toutes les marques' ? marque = null : marque = filterMarque
        const soinCheveux = await getSoinCheveuxByRating(summaryDiag);
        const soinLavants = await getSoinLavantByRating(summaryDiag);
        const best_soins = soinCheveux[0]['EAN PRODUIT ENTRETIEN'] + ', ' + soinLavants[0]['EAN PRODUIT ENTRETIEN']
        setState(prevState => ({
          ...prevState,
          json_data: {
            ...prevState.json_data,
            best_soins: best_soins
          }
        }));
        setSoinCheveux(soinCheveux);
        setSoinLavants(soinLavants);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    if (summaryDiag)
      fetchData();
  }, [summaryDiag, filterMarque, filterMarque1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let id = urlParams.get('id')
        if (!id) {
          if (titleNormalized.includes('ybera'))
            id = '2094429'
          else id = '2085475'
        }

        const res = await getDefaultBrand(id);
        if (res.default_brand) {
          setFilterMarque(res.default_brand)
          setFilterMarque1(res.default_brand)
        }

      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData();
  }, []);

  const getProductsByMarque = useCallback(async (type) => {
    try {
      let marque;
      selectedMarque === 'Toutes les marques' ? (marque = null) : (marque = selectedMarque);
      selectedMarque1 === 'Toutes les marques' ? (marque = null) : (marque = selectedMarque1);
      const data = await getProducts({ marque, type });
      if (type === 'soinCheveux')
        setSoinCheveuxByMarque(data)
      if (type === 'soinLavant')
        setSoinLavantsByMarque(data)
      goToNextSlide(type);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }, [selectedMarque]);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,
    animation: 'slide'
  };

  const style = {
    background: 'white',
    borderRadius: '8px',
    padding: '10px',
    cursor: 'pointer',
    minWidth: '200px',
    height: '25px'
  };

  return (
    <div ref={mainDivRef} style={{ width: '90%', height: 'fit-content', margin: 'auto' }}>
      {/* <FormControl style={{ marginTop: '15px', marginBottom: '15px' }}>
        <Select
          variant='standard'
          id="marque"
          value={filterMarque}
          label="Selectionnez une marque"
          onChange={(e) => setFilterMarque(e.target.value)}
          style={style}
          disableUnderline={true}
        >
          {brands.map((brand) => (
            <MenuItem key={brand} value={brand}>
              {brand}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <>
        {soinLavants && soinLavants.length > 0 && (
          <div style={{
            marginTop: 60
          }}>
            <Typography>Vos soins Lavants</Typography>
            <Slider ref={sliderRefSoinsLavants} {...settings}>
              {soinLavants.map((item, i) => (
                <div key={i}>
                  {/* Content of your slider item */}
                  <Item
                    item={item}
                    diag={summaryDiag}
                    gender={props.state.user.genre}
                    handleCheckedProducts={handleCheckedProducts}
                  />
                </div>
              ))}
              {
                <div key={4} >
                  <div style={{
                    width: '99%',
                    height: '120px',
                    backgroundColor: 'rgb(245, 245, 245)',
                    borderRadius: '12px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FormControl style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <InputLabel htmlFor="your-select">Voir tout le catalogue</InputLabel>
                      <Select
                        variant='standard'
                        id="your-select"
                        value={selectedMarque}
                        label="Voir tout le catalogue"
                        onChange={(e) => setSelectedMarque(e.target.value)}
                        style={style}
                        disableUnderline={true}
                      >
                        <MenuItem disabled value="">
                          <em>Selectionnez votre marque</em>
                        </MenuItem>
                        {brands.map((brand) => (
                          <MenuItem key={brand} value={brand}>
                            {brand}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>
                      <button
                        disabled={selectedMarque ? false : true}
                        onClick={() => {
                          getProductsByMarque('soinLavant');
                        }}
                        style={{
                          backgroundColor: selectedMarque ? '#8b9cd2' : '#f0f0f0',
                          color: selectedMarque ? 'white' : ' #a8a8a8',
                          marginTop: '12px',
                          auto: 'auto',
                          border: 'none'
                        }}>
                        Voir tous les soins lavants
                      </button>
                    </div>
                  </div>
                </div>
              }
              {soinLavantsByMarque && soinLavantsByMarque.length > 0 && soinLavantsByMarque.map((item, i) => (
                <div key={i}>
                  {/* Content of your slider item */}
                  <Item
                    item={item}
                    diag={summaryDiag}
                    gender={props.state.user.genre}
                    handleCheckedProducts={handleCheckedProducts} />
                </div>
              ))}
            </Slider>
          </div>
        )}
        {soinCheveux && soinCheveux.length > 0 && (
          <div style={{
            marginTop: 60
          }}>
            <Typography>Vos soins cheveux</Typography>
            <Slider ref={sliderRefSoinsCheveux} {...settings}>
              {soinCheveux.map((item, i) => (
                <div key={i}>
                  {/* Content of your slider item */}
                  <Item
                    item={item}
                    diag={summaryDiag}
                    gender={props.state.user.genre}
                    handleCheckedProducts={handleCheckedProducts}
                  />
                </div>
              ))}
              {
                <div key={4} >
                  <div style={{
                    width: '99%',
                    height: '120px',
                    backgroundColor: 'rgb(245, 245, 245)',
                    borderRadius: '12px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FormControl style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <InputLabel htmlFor="your-select">Voir tout le catalogue</InputLabel>
                      <Select
                        variant='standard'
                        id="your-select"
                        value={selectedMarque1}
                        label="Voir tout le catalogue"
                        onChange={(e) => setSelectedMarque1(e.target.value)}
                        style={style}
                        disableUnderline={true}
                      >
                        <MenuItem disabled value="">
                          <em>Selectionnez votre marque</em>
                        </MenuItem>
                        {brands.map((brand) => (
                          <MenuItem key={brand} value={brand}>
                            {brand}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>
                      <button
                        disabled={selectedMarque1 ? false : true}
                        onClick={() => {
                          getProductsByMarque('soinCheveux');
                        }}
                        style={{
                          backgroundColor: selectedMarque1 ? '#8b9cd2' : '#f0f0f0',
                          color: selectedMarque1 ? 'white' : ' #a8a8a8',
                          marginTop: '12px',
                          auto: 'auto',
                          border: 'none'
                        }}>
                        Voir tous les soins cheveux
                      </button>
                    </div>
                  </div>
                </div>
              }
              {soinCheveuxByMarque && soinCheveuxByMarque.length > 0 && soinCheveuxByMarque.map((item, i) => (
                <div key={i}>
                  {/* Content of your slider item */}
                  <Item
                    item={item}
                    diag={summaryDiag}
                    gender={props.state.user.genre}
                    handleCheckedProducts={handleCheckedProducts} />
                </div>
              ))}
            </Slider>
          </div>
        )}


      </>
      <div style={{ marginTop: '10px' }}>
        <OneButton
          payload={payload}
          checkedProducts={checkedProducts}
          checkedSoins={checkedSoins}
          states={props.state}>
        </OneButton>
      </div>
    </div >
  );
}

function Item(props) {

  const CHEVEUX = {
    courts: 'COURT',
    "mi-longs": 'MI_LONG',
    longs: "LONG",
    Très_long: "XL"
  }
  const [isChecked, setChecked] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const openPopup = (product) => {
    setSelectedProduct(product);
    setPopupOpen(true);
  };

  const style = {
    container: {
      width: '99%',
      height: '100%',
      borderRadius: '12px',
      border: '1px solid #000',
      overflow: 'hidden'
    },
    product: {
      background: 'white',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left'
    },
    image: {
      width: '25%',
      height: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      marginLeft: '3%',
      marginRight: '3%',
    },
    titre: {
      fontWeight: '700',
      fontSize: '10px'
    },
    description: {
      color: '#5b6577',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleCheckboxChange = () => {
    let lst = []
    lst.push(props.item['EAN PRODUIT ENTRETIEN'].split(', '))
    props.handleCheckedProducts(lst.flat(), !isChecked, props.item.uid)
    setChecked(!isChecked);
  };

  const prixAfficher = () => {
    const prixHomme = props.item['prix']["HOMME"];
    const prixCheveux = props.item['prix'][CHEVEUX[props.diag.longueur]] || props.item['prix'][CHEVEUX['courts']];
    const prixAffiche = props.gender === 'homme' ? (prixHomme || prixCheveux || 'Demander à votre coiffeur') : (prixCheveux || 'Demander à votre coiffeur');
    return prixAffiche
  }

  return (
    <div style={style.container}>
      <div onClick={() => openPopup(props.item)} style={style.product}>
        <img src={`https://api.effimetrix.care/images/${props.item['uid']}.png`} alt={props.item['Nom du soin']} style={style.image} />
        <div>
          <h2 style={style.titre}>{props.item['Nom du soin']}</h2>
          <p style={style.description}>{props.item['Déscription courte'].slice(0, 200) + '...'}</p>
          <p style={style.description}>Durée: {props.item['DUREE']}</p>
          <p style={style.description}>Type cheveux: {props.diag.longueur}</p>
          <p style={style.description}>Prix: {prixAfficher()}</p>

          <div onClick={(e) => e.stopPropagation()}>
            <span style={style.description}>Sélectionner ce soin</span>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>

      </div>
      <ProductPopup open={popupOpen} onClose={closePopup} product={selectedProduct} />
    </div>
  );
}

function ProductPopup({ open, onClose, product }) {

  if (!product) {
    return null; // or you can render a message or placeholder
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', width: '85%', maxHeight: '500px' }}>
        <img
          src={`https://api.effimetrix.care/images/${product['uid']}.png`}
          alt={product['Nom du soin']}
          style={{ maxWidth: '100%', maxHeight: '200px', margin: 'auto' }}
        />
        <DialogContentText style={{ whiteSpace: 'pre-wrap', font: '400 14px/20px Roboto, Helvetica Neue, sans-serif', textAlign: 'center', marginTop: '10px', color: 'black' }}>{product['Nom du soin']}</DialogContentText>
        <DialogContentText style={{ whiteSpace: 'pre-wrap', font: '400 14px/20px Roboto, Helvetica Neue, sans-serif', textAlign: 'center', marginTop: '10px', width: '80%', color: 'black' }}>{product['Déscription longue']}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );

}


export default ProductCarousel;
